<template>
  <div class="contact">
    <div class="contact-banner"></div>
    <div class="contact-wrapper">
      <div class="zlxc-container" style="overflow: visible">
        <div class="bread-crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>投诉建议</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="form-selection">
          <el-form label-position="left" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="rule-form">
            <el-form-item label="选择类型" prop="type">
              <div class="type-select">
                <p :class="['select-radio',{'is-action': ruleForm.type == '咨询'}]" @click="tapChangeType('咨询')">咨询</p>
                <p :class="['select-radio',{'is-action': ruleForm.type == '投诉'}]" @click="tapChangeType('投诉')">投诉</p>
              </div>
            </el-form-item>
            <el-form-item label="投诉类型" prop="complaint" v-if="ruleForm.type == '投诉'">
              <div class="type-select">
                <p :class="['select-radio',{'is-action': ruleForm.complaint == '1'}]" @click="tapChangeComplaint('1')">服务态度</p>
                <p :class="['select-radio',{'is-action': ruleForm.complaint == '2'}]" @click="tapChangeComplaint('2')">教学质量</p>
                <p :class="['select-radio',{'is-action': ruleForm.complaint == '3'}]" @click="tapChangeComplaint('3')">退费问题</p>
              </div>
            </el-form-item>
            <el-form-item label="投诉对象" prop="beau" v-if="ruleForm.type == '投诉'">
              <div class="type-select">
                <p :class="['select-radio',{'is-action': ruleForm.beau == '驾校'}]" @click="tapChangeBeau('驾校')">驾校</p>
                <p :class="['select-radio',{'is-action': ruleForm.beau == '教练'}]" @click="tapChangeBeau('教练')">教练</p>
              </div>
            </el-form-item>
            <el-form-item label="教练姓名" prop="coachName" v-if="ruleForm.type == '投诉' && ruleForm.beau == '教练'">
              <el-input v-model="ruleForm.coachName" placeholder="请输入教练姓名"></el-input>
            </el-form-item>
            <el-form-item label="驾校名称" prop="schoolName" v-if="ruleForm.type == '投诉'">
              <el-input v-model="ruleForm.schoolName" placeholder="请输入驾校名称"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="connect">
              <el-input v-model="ruleForm.connect" placeholder="请输入您的联系方式"></el-input>
            </el-form-item>
            <el-form-item label="内容详情" prop="desc">
              <el-input type="textarea" v-model="ruleForm.desc" placeholder="请输入您要咨询或投诉的内容"></el-input>
            </el-form-item>
            <!-- <el-form-item label="上传图片">
              <el-upload
                action="/app-api/infra/file/upload"
                list-type="picture-card"
                name="file"
                :limit="3"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryCreateComplaint } from '@/api/herbalMedicine'

export default {
  name: 'About',
  data () {
    return {
      ruleForm: {
        // 选择类型
        type: '咨询',
        // 投诉类型
        complaint: '',
        // 投诉对象
        beau: '',
        // 教练姓名
        coachName: '',
        // 驾校名称
        schoolName: '',
        // 联系方式
        connect: '',
        // 内容详情
        desc: ''
      },
      // 上传图片
      dialogImageUrl: '',
      dialogVisible: false,
      rules: {
        connect: [
          { required: true, message: '请输入您的联系方式', trigger: 'blur' },
          { min: 7, max: 11, message: '长度在 7 到 11 个数字', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '选择类型', trigger: 'change' }
        ],
        complaint: [
          { required: true, message: '选择投诉类型', trigger: 'change' }
        ],
        beau: [
          { required: true, message: '选择投诉对象', trigger: 'change' }
        ],
        coachName: [
          { required: true, message: '请输入教练姓名', trigger: 'blur' },
        ],
        schoolName: [
          { required: true, message: '请输入驾校名称', trigger: 'blur' },
        ],
        desc: [
          { required: true, message: '请输入您要咨询或投诉的内容', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {},
  methods: {
    // 创建投诉咨询
    getQueryCreateComplaint () {
      queryCreateComplaint({
        complainType: this.ruleForm.complaint,
        userType: this.ruleForm.beau=='驾校'?1:2 ,
        content: this.ruleForm.desc,
        // image: this.dialogImageUrl,
        coachName: this.ruleForm.coachName,
        schoolName: this.ruleForm.schoolName,
        phone: this.ruleForm.connect,
        type: this.ruleForm.type == '咨询' ? 1 : 2
      }).then(res => {
        if (res.code == 0) {
          this.$alert('提交成功', this.ruleForm.type, {
            confirmButtonText: '确定',
            callback: action => {
              this.$router.push({
                name:'Wrapper'
              })
            }
          });
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getQueryCreateComplaint()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    tapChangeType(type) {
      if (this.ruleForm.type == type) return
      if (type == '咨询') {
        this.ruleForm.complaint = ''
        this.ruleForm.beau = ''
      }
      this.ruleForm.type = type
    },
    tapChangeComplaint(complaint) {
      if (this.ruleForm.complaint == complaint) return
      this.ruleForm.complaint = complaint
    },
    tapChangeBeau(beau) {
      if (this.ruleForm.beau == beau) return
      this.ruleForm.beau = beau
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess(response, file, fileList) {
      console.log("response", response)
      console.log("file", file)
      console.log("fileList", fileList)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
